import React from 'react';
import { useSite } from '@unisporkal/sites';
import { useExperience } from '@unisporkal/experiences';
import { useSearchBar, useWindowSize } from '../../../../hooks';
import Suggestions from '../Suggestions/Suggestions';
import Preview from './Preview/Preview';
import styles from './SuggestionsPreview.module.scss';

const WINDOW_SIZE_THRESHOLD = 768;

const SuggestionsPreview = () => {
  const searchPreview = useExperience('SearchPreview');
  const searchBar = useSearchBar();
  const site = useSite();
  const windowSize = useWindowSize();

  const inSearchPreviewExperience = () =>
    searchPreview.isOfferActive('test') &&
    !searchBar.options.performSearch &&
    searchBar.searchParameters.assettype !== 'film' &&
    windowSize.windowWidth >= WINDOW_SIZE_THRESHOLD &&
    site.preventGatewaySrp;

  const contents = () => {
    if (inSearchPreviewExperience()) {
      return (
        <>
          <div className={styles.container}>
            <Suggestions />
          </div>
          <Preview />
        </>
      );
    }

    return <Suggestions />;
  };

  return contents();
};

export default SuggestionsPreview;
