import React, { createContext, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import reducer from '../../reducers/reducer';
import * as actionTypes from '../../actions/types';
import configPropType from '../../proptypes/configPropType';
import buildInitialState from './initial-state/buildInitialState';

const SearchBarContext = createContext();

const SearchBarProvider = ({ children, config, ...restOfProps }) => {
  const initialState = buildInitialState(config, restOfProps);
  const [state, dispatch] = useReducer(reducer, initialState);

  const updateSearchBar = (newConfig) => {
    dispatch({ type: actionTypes.SEARCH_BAR_UPDATE, config: newConfig });
  };

  const updateSearchBarSearchParameters = (parameters) => {
    dispatch({
      type: actionTypes.SEARCH_BAR_UPDATE_SEARCH_PARAMETERS,
      parameters,
    });
  };

  const setAutoSuggestContainerWidth = (width) => {
    dispatch({ type: actionTypes.SET_AUTOSUGGEST_CONTAINER_WIDTH, width });
  };

  const setStatusToUpdated = () => {
    dispatch({ type: actionTypes.SEARCH_BAR_UPDATED });
  };

  const setStatusToReady = () => {
    dispatch({ type: actionTypes.SEARCH_BAR_READY });
  };

  const updateSearchBarPeekyMode = (mode) => {
    dispatch({ type: actionTypes.SEARCH_BAR_UPDATE_PEEKY_MODE, mode });
  };

  const buildContextValue = () => ({
    updateSearchBar,
    updateSearchBarSearchParameters,
    setAutoSuggestContainerWidth,
    setStatusToUpdated,
    setStatusToReady,
    updateSearchBarPeekyMode,
    ...state,
  });
  const contextValue = buildContextValue();
  const memoizedContextValue = useMemo(buildContextValue, [contextValue.id]);

  return (
    <SearchBarContext.Provider value={memoizedContextValue}>
      {children}
    </SearchBarContext.Provider>
  );
};

SearchBarProvider.propTypes = {
  children: PropTypes.node.isRequired,
  config: configPropType.isRequired,
};

export default SearchBarProvider;
export { SearchBarContext };
