import { PathUtils } from '@unisporkal/utilities';

const getLastSearchScope = (site) => {
  let assettype;
  let family;

  const storedValue = window.localStorage?.getItem(
    'search.lastSearchParameters'
  );

  if (storedValue) {
    const params = PathUtils.decodeQueryParams(storedValue);
    ({ assettype, family } = params);
    // iStock does not save `family` in lastSearchParameters,
    // but search-bar still needs it.
    family = site.nickname === 'istock' ? 'creative' : family;
  }

  return { assettype, family };
};

export default getLastSearchScope;
