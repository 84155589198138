const configWithDefaults = (siteName, config = {}) => {
  const iStockDefaults = {
    options: {
      allowBlankSearches: false,
    },
    searchParameters: {
      assettype: 'image,film',
      family: 'creative',
    },
  };

  const gettyDefaults = {
    options: {
      allowBlankSearches: true,
      overlayOnFocus: true,
    },
    searchParameters: {
      assettype: 'image',
    },
  };

  const defaults = siteName === 'getty' ? gettyDefaults : iStockDefaults;

  return {
    ...config,
    options: {
      ...defaults.options,
      ...config.options,
    },
    searchParameters: {
      ...defaults.searchParameters,
      ...config.searchParameters,
    },
  };
};

export default configWithDefaults;
