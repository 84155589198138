import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { useSite } from '@unisporkal/sites';
import { useSpectrum, useSearchBar } from '../../../../hooks';
import { AutoSuggestContext } from '../AutoSuggestContext';
import iStockStyles from './RecentSearch.istock.module.scss';
import GettyStyles from './RecentSearch.getty.module.scss';
/* eslint-disable import/no-unresolved */
import SearchClose from '../../../../icons/shared/clear.svg?icon';
import IStockSearchIcon from '../../../../icons/istock/recentSearch.svg?icon';
import GettySearchIcon from '../../../../icons/getty/searchIcon.svg?icon';

const RecentSearch = ({ search, selected = false }) => {
  const searchBar = useSearchBar();
  const autoSuggest = useContext(AutoSuggestContext);

  const site = useSite();
  const styles = site.isGetty() ? GettyStyles : iStockStyles;
  const cx = classnames.bind(styles);
  const spectrum = useSpectrum();

  const handleRemoveSearch = (event, text) => {
    event.stopPropagation();
    autoSuggest.sendTracking('SEARCH_REMOVE');
    autoSuggest.removeRecentSearch(text);
    autoSuggest.setRecentSearches(
      autoSuggest.recentSearches.filter((s) => s.phrase !== text)
    );
  };

  const handleSelectSearch = (phrase) => {
    autoSuggest.sendTracking('SEARCH_CLICK');
    spectrum.sendItemSelectedSignal('recent_search', phrase);
    searchBar.updateSearchBarSearchParameters({ phrase });
    searchBar.updateSearchBar({ inFocus: false });
    searchBar.setStatusToUpdated();
  };

  const searchIcon = () => {
    if (site.isGetty()) {
      return <GettySearchIcon />;
    }
    return <IStockSearchIcon />;
  };

  return (
    <li
      data-testid="search-item"
      className={styles.recentSearchListItem}
    >
      <div
        className={cx(styles.recentSearch, { selected })}
        onClick={() => handleSelectSearch(search.phrase)}
        onKeyDown={() => handleSelectSearch(search.phrase)}
        data-testid="recent-search-select"
        role="button"
        tabIndex={0}
      >
        <div className={styles.holder}>
          <div className={styles.searchIcon}>{searchIcon()}</div>
          <span className={styles.link}>{search.phrase}</span>
        </div>
        <button
          className={styles.clearIcon}
          onClick={(event) => handleRemoveSearch(event, search.phrase)}
          data-testid="recent-search-remove"
          type="button"
        >
          <SearchClose />
        </button>
      </div>
    </li>
  );
};

RecentSearch.propTypes = {
  search: PropTypes.shape().isRequired,
  selected: PropTypes.bool.isRequired,
};

export default RecentSearch;
