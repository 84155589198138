import React, { Suspense, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@unisporkal/localization';
import { useSite } from '@unisporkal/sites';
import { ga4Track, modalInteraction } from '@unisporkal/ga4';
import { lazyComponent } from '@unisporkal/react-lazy-component';
import iStockStyles from './SearchByImageButton.istock.module.scss';
import GettyStyles from './SearchByImageButton.getty.module.scss';
/* eslint-disable import/no-unresolved */
import GettySearchByImageVideo from '../../icons/getty/searchByImageVideo.svg?icon';
import iStockSearchByImageVideo from '../../icons/istock/searchByImageVideo.svg?icon';

const SearchByImageModal = lazyComponent(() =>
  import('../SearchByImageModal/SearchByImageModal')
);

const SearchByImageButton = ({ assetType }) => {
  const t = useTranslations();
  const site = useSite();
  const isGetty = site.isGetty();
  const [isModalOpen, setIsModalOpen] = useState(false);

  let styles = iStockStyles;
  if (isGetty) {
    styles = GettyStyles;
  }

  const trackGA4Events = () => {
    const ga4Event = modalInteraction({
      event: 'open_modal',
      modal_content: 'search_by_asset',
      modal_type: 'detailed_modal',
      cta_text: undefined,
      ui_element_location: 'search_by_asset_camera_icon',
      modal_selection: 'search_by_image_button',
    });
    ga4Track(ga4Event);
  };

  const handleDragStart = (event) => {
    // CORS solution unitl/when/if we pull the image from the canvas
    const regex = /https:\/\/media\..*-{0,1}(istockphoto|gettyimages)\.com\/.*/;
    const imageUrl = event.target.currentSrc;
    if (regex.test(imageUrl)) {
      event.dataTransfer.setData('text/uri-list', imageUrl);
    }
  };

  useEffect(() => {
    document.addEventListener('dragstart', handleDragStart);
    return () => {
      document.removeEventListener('dragstart', handleDragStart);
    };
  });

  const handleOpenModal = (uiLocation) => {
    trackGA4Events(uiLocation);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => setIsModalOpen(false);

  const IconComponent = site.isGetty()
    ? GettySearchByImageVideo
    : iStockSearchByImageVideo;

  const SBIModal = useMemo(
    () => (
      <Suspense>
        <SearchByImageModal
          isOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          assetType={assetType}
        />
      </Suspense>
    ),
    [isModalOpen]
  );

  return (
    <div className={styles.holder}>
      <button
        className={styles.container}
        title={t('search_by_image_or_video')}
        onClick={() => handleOpenModal('camera_icon')}
        onDragEnter={() => handleOpenModal('drag_and_drop')}
        data-testid="search-by-image-button"
        type="button"
      >
        <IconComponent className={styles.icon} />
        <span className={styles.label}>{t('search_by_image_or_video')}</span>
      </button>
      {isModalOpen ? SBIModal : null}
    </div>
  );
};

SearchByImageButton.propTypes = {
  assetType: PropTypes.string.isRequired,
};

export default SearchByImageButton;
