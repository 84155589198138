import React, { startTransition, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { useSite, useSiteMap } from '@unisporkal/sites';
import { useTranslations } from '@unisporkal/localization';
import { useSearchBar, useSpectrum } from '../../../hooks';
import IstockIconSearch from '../../../icons/istock/search.svg?icon';
import GettyIconSearch from '../../../icons/getty/search.svg?icon';
import IconClear from '../../../icons/shared/clear.svg?icon';
import ContextButtons from '../ContextButtons/ContextButtons';
import iStockStyles from './SearchBox.istock.module.scss';
import GettyStyles from './SearchBox.getty.module.scss';

const SearchBox = ({ placeholders }) => {
  const searchBar = useSearchBar();
  const siteMap = useSiteMap();
  const spectrum = useSpectrum();
  const t = useTranslations();
  const site = useSite();
  const styles = site.isGetty() ? GettyStyles : iStockStyles;
  const IconSearch = site.isGetty() ? GettyIconSearch : IstockIconSearch;

  const phrase = searchBar.searchParameters.phrase || '';

  const blankSearch = () => phrase.length === 0;

  const submitSearch = () => {
    searchBar.updateSearchBar({ inFocus: false });
    searchBar.setStatusToUpdated();
    document?.activeElement?.blur();
    spectrum.sendItemSelectedSignal('searchbox', phrase);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      blankSearch() &&
      !searchBar.options.allowBlankSearches &&
      !searchBar.specialSearch()
    ) {
      return;
    }
    submitSearch();
  };

  const clearPhrase = () => {
    searchBar.updateSearchBarSearchParameters({ phrase: '' });
  };

  const onPhraseChange = (e) => {
    searchBar.updateSearchBar({
      inFocus: true,
    });
    searchBar.updateSearchBarSearchParameters({ phrase: e.target.value });
  };

  const onFocusIn = () => {
    startTransition(() => {
      searchBar.updateSearchBar({
        inFocus: true,
      });
    });
  };

  const placeholderKeyForScope = () => {
    const { assettype, assetfiletype, family, mediatype } =
      searchBar.searchParameters;
    const placeholderParts = [];
    if (assettype) {
      placeholderParts.push(assettype);
    }
    if (['creative', 'editorial'].includes(family)) {
      placeholderParts.push(family);
    }
    if (mediatype && mediatype !== 'unknown') {
      placeholderParts.push(mediatype);
      if (assetfiletype) {
        placeholderParts.push(assetfiletype);
      }
    }
    return placeholderParts.join('.');
  };

  const placeholder = () => {
    if (searchBar.options.placeholder) {
      return searchBar.options.placeholder;
    }
    if (searchBar.similarContentSearch()) {
      return t(placeholders.morelikethis);
    }
    if (searchBar.searchByImageSearch()) {
      return t(placeholders.searchbyimage);
    }
    if (searchBar.imageAndFilmSearch()) {
      return t(placeholders.imagefilm);
    }
    const key = placeholders[placeholderKeyForScope()];
    return t(key || 'image');
  };

  const containerStyles = useMemo(() => {
    const cx = classnames.bind(styles.container);
    return cx(styles.container, {
      [styles.containerSearchbarRefresh]: site.isIstock(),
    });
  }, [site.isIstock()]);

  const SearchButton = useMemo(
    () => (
      <button
        aria-label="search"
        className={styles.buttonSearch}
        onClick={(e) => onSubmit(e)}
        type="button"
      >
        <IconSearch
          className={styles.IconSearch}
          viewBox="0 0 48 48"
        />
      </button>
    ),
    []
  );

  const showSearchButton = () => (site.isGetty() ? SearchButton : null);

  return (
    <div
      className={containerStyles}
      data-testid="container-search-box"
    >
      {showSearchButton()}
      <ContextButtons />
      <form
        aria-label="form"
        method="GET"
        className={styles.form}
        action={siteMap.searchUrl(searchBar.searchParameters.assettype)}
        onSubmit={(e) => onSubmit(e)}
      >
        <input
          aria-label="text"
          autoComplete="off"
          className={styles.input}
          data-autosuggest-from="https://as.gettyservices.com:443/GettyImages.Autocomplete.KeywordService.Service/KeywordService1/Suggestedkeywords/{0}/{1}/{2}/{3}/{4}?usePopularity=true"
          data-testid="container-search-box-input"
          name="phrase"
          placeholder={placeholder()}
          onChange={onPhraseChange}
          onClick={onFocusIn}
          onFocus={onFocusIn}
          value={phrase || ''}
        />
      </form>
      {phrase?.length > 0 && (
        <button
          aria-label="clear"
          className={styles.buttonClear}
          onClick={clearPhrase}
          type="button"
        >
          <IconClear className={styles.iconClear} />
        </button>
      )}
    </div>
  );
};

SearchBox.propTypes = {
  placeholders: PropTypes.shape().isRequired,
};

export default SearchBox;
