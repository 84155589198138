import axios from 'axios';
import { PathUtils } from '@unisporkal/utilities';

// SUGGESTIONS
export const getSuggestions = (options, searchPhrase) => {
  const assettype =
    options.searchAssetType === 'image,film'
      ? 'image'
      : options.searchAssetType;
  const url = `${options.endpoint}/${options.siteBundleId}/${options.siteLocale}/${assettype}/${searchPhrase}/${options.searchAssetFamily}?usePopularity=true`;
  const response = axios.get(url);
  return response.then((res) =>
    res.data.CompletedKeywords.map((keyword) => keyword.DisplayText)
  );
};

export const getSuggestionPreview = (url) => {
  const params = {
    pagesize: 12,
    responsefields: 'gallery,totalNumberOfResults',
    servicecontext: 'srp-preview',
  };
  const config = {
    headers: {
      Accept: 'application/json',
    },
  };
  const previewUrl = PathUtils.appendQueryParamsToString(url, params);
  const response = axios.get(previewUrl, config);
  return response.then((r) => {
    const { data, headers } = r;

    return { ...data, request_id: headers['x-request-id'] };
  });
};

// RECENT SEARCHES
export const getRecentSearches = () => {
  const { localStorage } = window;
  const searches = JSON.parse(localStorage.getItem('search.recentSearches'));
  if (searches) {
    const array = Object.keys(searches).map((search) => searches[search]);
    return array.sort((search1, search2) => search2.date - search1.date);
  }
  return [];
};

export const clearRecentSearches = () => {
  const { localStorage } = window;
  localStorage.setItem('search.recentSearches', JSON.stringify({}));
};

export const removeRecentSearch = (key) => {
  const { localStorage } = window;
  const recentSearches = JSON.parse(
    localStorage.getItem('search.recentSearches')
  );
  delete recentSearches[key];
  localStorage.setItem('search.recentSearches', JSON.stringify(recentSearches));
};

// RECENT VIEWED ASSETS
export const getRecentViewedAssets = () => {
  const { localStorage } = window;
  const assets = JSON.parse(localStorage.getItem('search.assetsViewedData'));
  if (assets) {
    return assets;
  }
  return [];
};

export const removeRecentlyViewedAsset = (id) => {
  const { localStorage } = window;
  const recentAssets = JSON.parse(
    localStorage.getItem('search.assetsViewedData')
  );
  recentAssets.splice(
    recentAssets.findIndex((item) => item.id === id),
    1
  );
  localStorage.setItem('search.assetsViewedData', JSON.stringify(recentAssets));
};

export const clearRecentlyViewedAssets = () => {
  const { localStorage } = window;
  localStorage.setItem('search.assetsViewedData', JSON.stringify([]));
};
