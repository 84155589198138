import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSite } from '@unisporkal/sites';
import { useExperience } from '@unisporkal/experiences';
import {
  useHover,
  useSearchBar,
  useSpectrum,
  useWindowSize,
} from '../../../../hooks';
import { AutoSuggestContext } from '../AutoSuggestContext';
import iStockStyles from './Suggestion.istock.module.scss';
import GettyStyles from './Suggestion.getty.module.scss';
/* eslint-disable import/no-unresolved */
import IStockSearchIcon from '../../../../icons/istock/recentSearch.svg?icon';
import GettySearchIcon from '../../../../icons/getty/searchIcon.svg?icon';
import SearchClose from '../../../../icons/shared/clear.svg?icon';

const Suggestion = ({
  suggestion,
  suggestionsPhrase,
  withRemove = false,
  selected,
  index,
}) => {
  const searchBar = useSearchBar();
  const spectrum = useSpectrum();
  const autoSuggest = useContext(AutoSuggestContext);
  const element = useRef();
  const [hover] = useHover(element);
  const searchPreview = useExperience('SearchPreview');
  const windowSize = useWindowSize();

  const site = useSite();
  const styles = site.isGetty() ? GettyStyles : iStockStyles;
  const SearchIcon = site.isGetty() ? GettySearchIcon : IStockSearchIcon;

  const WINDOW_SIZE_THRESHOLD = 768;

  const selectSuggestion = () => {
    searchBar.updateSearchBarSearchParameters({ phrase: suggestion });
    searchBar.updateSearchBar({ inFocus: false });
    searchBar.setStatusToUpdated();
    autoSuggest.sendTracking('SUGGESTION_CLICK');
    spectrum.sendItemSelectedSignal('suggestion', suggestion);
  };

  const removeRecentSearch = () => {
    autoSuggest.sendTracking('SEARCH_REMOVE');
    autoSuggest.removeRecentSearch(suggestion);
    autoSuggest.setRecentSearches(
      autoSuggest.recentSearches.filter(
        (search) => search.phrase !== suggestion
      )
    );
  };

  const getItemStyle = () => {
    if (selected) {
      return styles.selectedItem;
    }
    return styles.item;
  };

  const inSearchPreviewExperience = () =>
    searchPreview.isOfferActive('test') &&
    !searchBar.options.performSearch &&
    searchBar.searchParameters.assettype !== 'film' &&
    windowSize.windowWidth >= WINDOW_SIZE_THRESHOLD &&
    site.preventGatewaySrp;

  useEffect(() => {
    if (hover && inSearchPreviewExperience()) {
      autoSuggest.setKeyIndex(index);
      searchBar.updateSearchBarSearchParameters({ phrase: suggestion });
    }
  }, [hover]);

  const getText = () => {
    if (!suggestion.includes(suggestionsPhrase)) {
      return suggestion;
    }

    const splitSuggestionArray = suggestion.split(suggestionsPhrase);
    const elements = splitSuggestionArray.reduce((acc, fragment, i) => {
      if (fragment) {
        acc.push(fragment);
      }
      if (i < splitSuggestionArray.length - 1) {
        // eslint-disable-next-line react/no-array-index-key
        acc.push(<span key={`${suggestion}-${i}`}>{suggestionsPhrase}</span>);
      }
      return acc;
    }, []);

    return elements;
  };

  const suggestionWithRemove = () => (
    <>
      <div className={styles.searchIcon}>
        <SearchIcon />
      </div>
      <button
        ref={element}
        className={styles.link}
        onClick={() => selectSuggestion()}
        data-testid="suggestion-item-selected"
        type="button"
      >
        {getText()}
      </button>
      <button
        className={styles.clearIcon}
        onClick={() => removeRecentSearch()}
        data-testid="suggestion-item-with-remove"
        type="button"
      >
        <SearchClose />
      </button>
    </>
  );

  const suggestionOnly = () => (
    <>
      <div className={styles.searchIcon} />
      <button
        ref={element}
        className={styles.link}
        onClick={() => selectSuggestion()}
        data-testid="suggestion-item"
        type="button"
      >
        {getText()}
      </button>
    </>
  );

  return (
    <li
      className={getItemStyle()}
      data-testid="suggestion"
    >
      {withRemove ? suggestionWithRemove() : suggestionOnly()}
    </li>
  );
};

Suggestion.propTypes = {
  index: PropTypes.number.isRequired,
  suggestion: PropTypes.string.isRequired,
  suggestionsPhrase: PropTypes.string.isRequired,
  withRemove: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default Suggestion;
