import { useContext, useMemo } from 'react';
import { SearchBarContext } from '../providers';
import SearchBarView from '../views';

const useSearchBar = () => {
  const searchBar = useContext(SearchBarContext);
  return useMemo(() => new SearchBarView(searchBar), [searchBar]);
};

export default useSearchBar;
