// Please keep in alphabetical order TY
const emptyState = {
  config: {
    autoSuggestContainerWidth: 0,
  },
  contributor: {},
  id: 0,
  inFocus: false,
  options: {
    allowBlankSearches: false,
    hideOnScroll: true,
    mobileMediaFilter: true,
    overlayOnFocus: false,
    peekyScroll: false,
    performSearch: true,
    placeholder: null,
    rememberLastSearchScope: false,
    showSearchByImageThumb: false,
    isNLSExample: false,
  },
  peekyScrollMode: 'none',
  queryParameters: {},
  searchParameters: {
    assettype: 'image',
    assetfiletype: null,
    contributor: null,
    family: null,
    imageurl: null,
    mediatype: null,
    morelikethis: null,
    phrase: '',
    searchbyimage: null,
    recommendconfig: null,
  },
  similarAsset: {},
  status: 'ready',
  totalResults: null,
};

export default emptyState;
