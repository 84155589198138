import React, { useState, useEffect } from 'react';
import { useLocale } from '@unisporkal/localization';
import { useSite } from '@unisporkal/sites';
import { useSearchPathBuilder } from '@unisporkal/search-url-builder';
import {
  useDebouncedEffect,
  useSearchBar,
  useSpectrum,
} from '../../../../../hooks';
import { getSuggestionPreview } from '../../../../../services/autosuggest';
import PreviewAsset from './PreviewAsset/PreviewAsset';
import styles from './Preview.module.scss';

const DEBOUNCE_TIME = 500;

const Preview = () => {
  const locale = useLocale();
  const spectrum = useSpectrum();
  const [preview, setPreview] = useState();
  const [cache, setCache] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const searchBar = useSearchBar();
  const site = useSite();
  const searchPathBuilder = useSearchPathBuilder();

  const phrase = searchBar.searchParameters.phrase || '';

  const trackPreviewEvent = (data) => {
    const trackingData = {
      asset_ids: data.gallery.assets.map((asset) => asset.id),
      phrase,
    };

    spectrum.sendSearchSignal(trackingData, { request_id: data.request_id });
  };

  const previewUrl = () => {
    const queryParameters = {
      ...searchBar.queryParameters,
      phrase,
    };
    return searchPathBuilder.searchPath(queryParameters, site.nickname);
  };

  useDebouncedEffect(
    () => {
      if (!searchBar.inFocus || phrase === '') {
        return;
      }

      const cachedPreview = cache[phrase];
      if (cachedPreview) {
        setPreview(cachedPreview);
        setIsLoading(false);
        trackPreviewEvent(cachedPreview);
      } else {
        const url = previewUrl();

        getSuggestionPreview(url).then((p) => {
          const updatedCache = { [phrase]: p };

          setCache({ ...cache, ...updatedCache });
          setPreview(p);
          setIsLoading(false);
          trackPreviewEvent(p);
        });
      }
    },
    DEBOUNCE_TIME,
    [phrase, searchBar.inFocus]
  );

  useEffect(() => {
    setCache({});
    setPreview(null);
  }, [searchBar.status]);

  useEffect(() => {
    setIsLoading(true);
  }, [phrase]);

  const executeSearch = () => {
    searchBar.updateSearchBarSearchParameters({ phrase });
    searchBar.updateSearchBar({ inFocus: false });
    searchBar.setStatusToUpdated();
    spectrum.sendItemSelectedSignal('preview_view_all', null, {
      request_id: preview.request_id,
    });
  };

  const trackPreviewAssetClick = (assetId) => {
    spectrum.sendItemSelectedSignal('preview_thumb', assetId, {
      request_id: preview.request_id,
    });
  };

  const shouldShow = () => searchBar.inFocus && preview && phrase && !isLoading;

  const contents = () => {
    if (shouldShow()) {
      return (
        <div
          className={styles.container}
          data-testid="preview"
        >
          <div className={styles.headerContainer}>
            <p className={styles.header}>{`Results preview for "${phrase}"`}</p>
            <button
              className={styles.srpLink}
              onClick={() => executeSearch()}
              data-testid="view-all-link"
              type="button"
            >
              {`View ${preview.totalNumberOfResults.toLocaleString(
                locale
              )} results >`}
            </button>
          </div>
          <div className={styles.grid}>
            {preview.gallery.assets.map((asset) => (
              <PreviewAsset
                key={asset.id}
                asset={asset}
                handleOnClick={() => trackPreviewAssetClick(asset.id)}
              />
            ))}
          </div>
        </div>
      );
    }

    return null;
  };

  return contents();
};

export default Preview;
