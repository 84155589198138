import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { useSite } from '@unisporkal/sites';
import { searchInteraction, ga4Track } from '@unisporkal/ga4';
import { useSearchBar, useWindowSize } from '../../../hooks';
import * as AutoSuggestService from '../../../services/autosuggest';
import { AutoSuggestProvider } from './AutoSuggestContext';
import RecentSearches from './RecentSearches/RecentSearches';
import RecentAssets from './RecentAssets/RecentAssets';
import SuggestionsPreview from './SuggestionsPreview/SuggestionsPreview';
import iStockStyles from './AutoSuggest.istock.module.scss';
import GettyStyles from './AutoSuggest.getty.module.scss';

const AutoSuggest = ({ options }) => {
  const searchBar = useSearchBar();
  const [recentSearches, setRecentSearches] = useState([]);
  const [recentAssets, setRecentAssets] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [keyIndex, setKeyIndex] = useState(-1);
  const [innerWidth, setInnerWidth] = useState(0);
  const windowSize = useWindowSize();

  const WINDOW_SIZE_THRESHOLD = 768;
  const isMobile = windowSize.windowWidth < WINDOW_SIZE_THRESHOLD;

  const site = useSite();
  const styles = site.isGetty() ? GettyStyles : iStockStyles;
  const phrase = searchBar.searchParameters.phrase || '';

  const isSearches = () => phrase.length === 0;

  const isSuggestions = () => phrase.length > 0;

  const minKeyIndex = 0;
  const maxKeyIndex = suggestions.length - 1;

  const applyIndex = (newIndex) => {
    const newPhrase = suggestions[newIndex];
    setKeyIndex(newIndex);
    searchBar.updateSearchBarSearchParameters({ phrase: newPhrase });
  };

  const keyPressAllowed = () =>
    searchBar.inFocus && isSuggestions() && suggestions.length >= 1;

  const handleUpKey = () => {
    if (!keyPressAllowed()) {
      return;
    }
    let newIndex;
    if ([-1, minKeyIndex].includes(keyIndex)) {
      newIndex = maxKeyIndex;
    } else {
      newIndex = keyIndex - 1;
    }
    applyIndex(newIndex);
  };

  const handleDownKey = () => {
    if (!keyPressAllowed()) {
      return;
    }
    let newIndex;
    if ([-1, maxKeyIndex].includes(keyIndex)) {
      newIndex = minKeyIndex;
    } else {
      newIndex = keyIndex + 1;
    }
    applyIndex(newIndex);
  };

  const DOWN_KEY = 40;
  const UP_KEY = 38;
  const ESC_KEY = 27;
  const handleKeyPress = (e) => {
    switch (e.keyCode) {
      case DOWN_KEY:
        handleDownKey();
        break;
      case UP_KEY:
        handleUpKey();
        break;
      case ESC_KEY:
        setKeyIndex(-1);
        searchBar.updateSearchBar({ inFocus: false });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    setSuggestions([]);
  }, [searchBar.status]);

  useEffect(() => {
    if (isSearches() || !searchBar.inFocus) {
      setKeyIndex(-1);
    }
  }, [phrase, searchBar.inFocus]);

  useEffect(() => {
    if (searchBar.inFocus && isSearches()) {
      const ga4Event = searchInteraction({
        event_name: 'search_assist_options_shown',
        search_selection: 'recent_searches,recently_viewed',
        ui_element_location: 'search_bar_autosuggest',
      });

      ga4Track(ga4Event);
    }
  }, [searchBar.inFocus]);

  useEffect(() => {
    setInnerWidth(window.innerWidth);
  }, []);

  // eslint-disable-next-line complexity
  const sendTracking = (eventName) => {
    let ga4Event;
    switch (eventName) {
      case 'SEARCH_CLEAR_ALL':
        ga4Event = searchInteraction({
          event_name: 'select_search_assist_option',
          ui_element_location: 'search_bar_assist_window',
          search_selection: 'recent_searches-remove_all',
        });
        break;

      case 'SEARCH_CLICK':
        window.localStorage.setItem(
          'tracking-data.search-tools',
          'autosuggest-recent'
        );
        ga4Event = searchInteraction({
          event_name: 'select_search_assist_option',
          ui_element_location: 'search_bar_assist_window',
          search_selection: 'recent_searches-keyword',
        });
        break;

      case 'SEARCH_REMOVE':
        ga4Event = searchInteraction({
          event_name: 'select_search_assist_option',
          ui_element_location: 'search_bar_assist_window',
          search_selection: 'recent_searches-remove_keyword',
        });
        break;

      case 'ASSET_VIEW_ALL':
        window.localStorage.setItem(
          'search.recently_viewed_tracking_data',
          'true'
        );
        ga4Event = searchInteraction({
          event_name: 'select_search_assist_option',
          ui_element_location: 'search_bar_autosuggest',
          search_selection: 'recently_viewed-view_all',
        });
        break;

      case 'ASSET_CLEAR_ALL':
        ga4Event = searchInteraction({
          event_name: 'select_search_assist_option',
          ui_element_location: 'search_bar_assist_window',
          search_selection: 'recently_viewed-remove_all',
        });
        break;

      case 'ASSET_CLICK':
        window.giLocalStorage.setItem(
          'search.recently_viewed_tracking_data',
          'true'
        );
        ga4Event = searchInteraction({
          event_name: 'select_search_assist_option',
          ui_element_location: 'search_bar_autosuggest',
          search_selection: 'recently_viewed-thumbnail',
        });
        break;

      case 'ASSET_REMOVE':
        ga4Event = searchInteraction({
          event_name: 'select_search_assist_option',
          ui_element_location: 'search_bar_assist_window',
          search_selection: 'recently_viewed-remove_asset',
        });
        break;

      case 'SUGGESTION_CLICK':
        window.localStorage.setItem(
          'tracking-data.search-tools',
          'autosuggest'
        );
        ga4Event = searchInteraction({
          event_name: 'select_search_assist_option',
          ui_element_location: 'search_bar_autosuggest',
          search_selection: 'autosuggest-keyword',
        });
        break;

      default:
        break;
    }
    ga4Track(ga4Event);
  };

  const getRecentSearches = () => AutoSuggestService.getRecentSearches();

  const clearRecentSearches = () => AutoSuggestService.clearRecentSearches();

  const removeRecentSearch = (text) =>
    AutoSuggestService.removeRecentSearch(text);

  const getRecentViewedAssets = () =>
    AutoSuggestService.getRecentViewedAssets();

  const clearRecentlyViewedAssets = () =>
    AutoSuggestService.clearRecentlyViewedAssets();

  const removeRecentlyViewedAsset = (id) =>
    AutoSuggestService.removeRecentlyViewedAsset(id);

  const getSuggestions = (suggestionsPhrase) =>
    AutoSuggestService.getSuggestions(options, suggestionsPhrase);

  const autosuggestOptions = {
    ...options,

    keyIndex,
    setKeyIndex,

    recentSearches,
    setRecentSearches,
    recentAssets,
    setRecentAssets,
    suggestions,
    setSuggestions,

    sendTracking,
    getRecentSearches,
    removeRecentSearch,
    clearRecentSearches,
    getRecentViewedAssets,
    clearRecentlyViewedAssets,
    removeRecentlyViewedAsset,
    getSuggestions,
  };

  const containerStyles = useMemo(() => {
    const cx = classnames.bind(styles.container);
    return cx(styles.container, {
      [styles.containerSearchbarRefresh]: site.isIstock(),
      [styles.hidden]: !searchBar.inFocus,
    });
  }, [site.isIstock(), searchBar.inFocus]);

  const dynamicContainerPositioningOverrides = useMemo(
    () => ({
      marginLeft:
        site.isIstock() &&
        !isMobile &&
        innerWidth > WINDOW_SIZE_THRESHOLD &&
        Number.isInteger(searchBar.autoSuggestContainerWidth())
          ? `${searchBar.autoSuggestContainerWidth()}px`
          : 0,
      paddingTop: '20px',
    }),

    [
      site.isIstock(),
      searchBar.autoSuggestContainerWidth(),
      windowSize.windowWidth,
    ]
  );

  return (
    <AutoSuggestProvider options={autosuggestOptions}>
      <div
        style={dynamicContainerPositioningOverrides}
        className={containerStyles}
      >
        <RecentSearches />
        {!isMobile ? <RecentAssets /> : null}
        <SuggestionsPreview />
      </div>
    </AutoSuggestProvider>
  );
};

AutoSuggest.propTypes = { options: PropTypes.shape().isRequired };

export default AutoSuggest;
