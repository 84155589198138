import { useEffect, useState } from 'react';

export default (ref, delay = 125) => {
  const [hover, setHover] = useState(false);
  const [hovered, setHovered] = useState(false);
  let id;

  const onMouseEnter = () => {
    id = setTimeout(() => {
      setHover(true);
      setHovered(true);
    }, delay);
  };

  const onMouseLeave = () => {
    clearTimeout(id);
    setHover(false);
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('mouseenter', onMouseEnter);
      ref.current.addEventListener('mouseleave', onMouseLeave);
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener('mouseenter', onMouseEnter);
        ref.current.removeEventListener('mouseleave', onMouseLeave);
      }
    };
  }, []);

  return [hover, hovered];
};
