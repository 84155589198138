import React, { useRef, useDeferredValue } from 'react';
import { useLocale } from '@unisporkal/localization';
import { useOutsideAlerter, useSearchBar } from '../../../hooks';
import MobileMediaFilter from '../MediaFilter/MobileMediaFilter';
import DesktopMediaFilter from '../MediaFilter/DesktopMediaFilter';
import { AutoSuggest, SearchBar, SearchBox, SearchByImage } from '../../shared';
import styles from './IstockSearchBar.module.scss';

const VerticalRule = () => <span className={styles.VerticalRule} />;

const placeholders = {
  searchbyimage: 'search_box.search_bar_similar_images_placeholder',
  morelikethis: 'search_box.search_bar_similar_images_placeholder',
  image: 'general_search_hint_with_video',
  'image.creative': 'general_search_hint_with_video',
  'image.creative.photography': 'general_search_hint_with_video',
  'image.creative.illustration': 'general_search_hint_with_video',
  'image.creative.illustration.eps': 'general_search_hint_with_video',
  film: 'general_search_hint_with_video',
  'film.creative': 'general_search_hint_with_video',
  imagefilm: 'general_search_hint_with_video',
};

const IstockSearchBar = () => {
  const containerRef = useRef(null);
  useOutsideAlerter(containerRef);
  const searchBar = useSearchBar();
  const locale = useLocale();
  const showMobileMediaFilter = useDeferredValue(
    searchBar.options.mobileMediaFilter && searchBar.inFocus
  );

  const autoSuggestOptions = {
    endpoint:
      'https://as.gettyservices.com:443/GettyImages.Autocomplete.KeywordService.Service/KeywordService1/Suggestedkeywords',
    siteLocale: searchBar.locale || locale,
    searchAssetType: searchBar.searchParameters.assettype || 'image',
    searchAssetFamily: searchBar.searchParameters.family || 'any',
  };

  return (
    <SearchBar styles={styles}>
      <div
        className={styles.container}
        ref={containerRef}
      >
        <div className={styles.holder}>
          <DesktopMediaFilter />
          <VerticalRule />
          <SearchBox placeholders={placeholders} />
          <SearchByImage />
        </div>
        {showMobileMediaFilter && (
          <div className={styles.mediaFilter}>
            <MobileMediaFilter />
          </div>
        )}
        <AutoSuggest options={autoSuggestOptions} />
      </div>
    </SearchBar>
  );
};

export default IstockSearchBar;
