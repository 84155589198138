import React, { useEffect, useContext } from 'react';
import { useTranslations } from '@unisporkal/localization';
import { useSite } from '@unisporkal/sites';
import { useSearchBar } from '../../../../hooks';
import { AutoSuggestContext } from '../AutoSuggestContext';
import RecentAsset from '../RecentAsset/RecentAsset';
import iStockStyles from './RecentAssets.istock.module.scss';
import GettyStyles from './RecentAssets.getty.module.scss';

const RecentAssets = () => {
  const site = useSite();
  const t = useTranslations();
  const searchBar = useSearchBar();
  const autoSuggest = useContext(AutoSuggestContext);

  const styles = site.isGetty() ? GettyStyles : iStockStyles;

  const phrase = searchBar.searchParameters.phrase || '';

  useEffect(() => {
    const assets = autoSuggest.getRecentViewedAssets();
    autoSuggest.setRecentAssets(
      assets.slice(0, autoSuggest?.maxAssetSize || 8)
    );
  }, [searchBar.inFocus]);

  const handleClearAll = () => {
    autoSuggest.sendTracking('ASSET_CLEAR_ALL');
    autoSuggest.clearRecentlyViewedAssets();
    autoSuggest.setRecentAssets([]);
  };

  const handleViewAll = () => {
    autoSuggest.sendTracking('ASSET_VIEW_ALL');
  };

  const showRecentAssets = () => {
    if (
      searchBar.inFocus &&
      phrase.length === 0 &&
      autoSuggest.recentAssets.length > 0
    ) {
      return true;
    }
    return false;
  };

  if (showRecentAssets()) {
    return (
      <div className={styles.container}>
        <header
          className={styles.header}
          data-testid="assets-header"
        >
          <div className={styles.title}>{t('recently_viewed_caps')}</div>
          <div className={styles.textDivider} />
          <button
            className={styles.clearAll}
            onClick={() => handleClearAll()}
            data-testid="recent-assets-clear-all"
            type="button"
          >
            {t('clear_all')}
          </button>
          <a
            className={styles.viewAll}
            href="/search/recently-viewed"
            onClick={() => handleViewAll()}
            data-testid="recent-assets-view-all"
          >
            {t('view_all_caps')} {'>'}
          </a>
        </header>
        <div
          className={styles.assetContainer}
          data-testid="assets-list"
        >
          {autoSuggest.recentAssets.map((asset) => (
            <RecentAsset
              asset={asset}
              key={asset.id}
            />
          ))}
        </div>
      </div>
    );
  }
  return null;
};

export default RecentAssets;
