import React from 'react';
import SearchByImageButton from './SearchByImageButton/SearchByImageButton';
import { useSearchBar } from '../../../../hooks';

const SearchByImage = () => {
  const searchBar = useSearchBar();
  const shouldShow = () =>
    searchBar.creativeSearch() ||
    (searchBar.filmSearch() && !searchBar.editorialSearch());

  if (shouldShow()) {
    return <SearchByImageButton assetType={searchBar.assetType()} />;
  }
  return null;
};

export default SearchByImage;
