import { useEffect, useRef } from 'react';
import useSearchBar from './useSearchBar';

export default (domRef) => {
  const searchBar = useSearchBar();
  const modelRef = useRef();
  modelRef.current = searchBar;

  const handleClickOutside = (event) => {
    if (
      modelRef.current.inFocus &&
      domRef.current &&
      !domRef.current.contains(event.target)
    ) {
      modelRef.current.updateSearchBar({ inFocus: false });
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
};
