import React from 'react';
import PropTypes from 'prop-types';
import { useSiteMap } from '@unisporkal/sites';
import styles from './PreviewAsset.module.scss';

const PreviewAsset = ({ asset, handleOnClick }) => {
  const siteMap = useSiteMap();

  const mosaicImageDimensions = () => {
    const maxImageWidth = asset.maxDimensions.width
      ? asset.maxDimensions.width
      : 0;
    const maxImageHeight = asset.maxDimensions.height
      ? asset.maxDimensions.height
      : 0;
    const desiredSize = 200;
    const ratio = Math.min(
      desiredSize / maxImageWidth,
      desiredSize / maxImageHeight
    );

    return {
      width: Math.floor(maxImageWidth * ratio),
      height: Math.floor(maxImageHeight * ratio),
    };
  };

  const imageDimensions = mosaicImageDimensions();

  return (
    <div
      className={styles.asset}
      style={{
        '--width': imageDimensions.width,
        '--height': imageDimensions.height,
      }}
      onClick={handleOnClick}
      onKeyDown={handleOnClick}
      data-testid="preview-asset"
      role="presentation"
    >
      <a
        href={siteMap.prefixedPath(asset.landingUrl)}
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt={asset.altText}
          src={asset.thumbUrl}
        />
      </a>
    </div>
  );
};

export default PreviewAsset;

PreviewAsset.propTypes = {
  asset: PropTypes.shape().isRequired,
  handleOnClick: PropTypes.func.isRequired,
};
