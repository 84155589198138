import { useEffect, useRef } from 'react';

export default () => {
  const windowSize = useRef({
    windowWidth: undefined,
    windowHeight: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      windowSize.current = {
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      };
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize.current;
};
