import { useSpectrum } from '@unisporkal/tracking';
import { useScroll } from '@unisporkal/utilities';

const SCROLL_HEIGHT = 150;

export default () => {
  const spectrum = useSpectrum();
  const scroll = useScroll({ threshold: SCROLL_HEIGHT });

  const sendItemSelectedSignal = (name, value, properties = {}) => {
    const searchBarMode = scroll.isNone()
      ? 'search_bar_static_mode'
      : 'search_bar_scroll_mode';
    spectrum.sendItemSelectedSignal(searchBarMode, name, value, properties);
  };

  return {
    ...spectrum,
    sendItemSelectedSignal,
  };
};
