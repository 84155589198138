import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

export const AutoSuggestContext = createContext();

export const AutoSuggestProvider = ({ children, options }) => {
  const initialOptions = {
    previousSearchPhrase: '',
    siteBundleId: 705,
    siteLocale: 'en-us',
    searchAssetType: 'image',
    searchAssetFamily: 'creative',
    maxAssetSize: 8,
  };

  const value = useMemo(() => ({ ...initialOptions, ...options }), [options]);

  return (
    <AutoSuggestContext.Provider value={value}>
      {children}
    </AutoSuggestContext.Provider>
  );
};

AutoSuggestProvider.propTypes = {
  children: PropTypes.element.isRequired,
  options: PropTypes.shape().isRequired,
};
