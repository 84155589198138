import React, { useState, useEffect } from 'react';
import IstockSearchBar from '../IstockSearchBar/IstockSearchBar';

import styles from './IstockCollapsibleSearchBar.module.scss';

const IstockCollapsibleSearchBar = () => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapsed = () => setCollapsed(!collapsed);

  useEffect(() => {
    const searchBarToggle = document.getElementById('search-bar-toggle');
    searchBarToggle?.addEventListener('click', toggleCollapsed);

    return () => searchBarToggle?.removeEventListener('click', toggleCollapsed);
  }, [toggleCollapsed]);

  return (
    <div className={styles.collapsibleSearchBar__container}>
      <div
        data-testid="collapsible-search-bar"
        className={`${styles.collapsibleSearchBar} ${
          collapsed
            ? styles.collapsibleSearchBar__collapsed
            : styles.collapsibleSearchBar__expanded
        }`}
      >
        <IstockSearchBar />
      </div>
    </div>
  );
};

export default IstockCollapsibleSearchBar;
