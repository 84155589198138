import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSite, useSiteMap } from '@unisporkal/sites';
import { Image } from '@unisporkal/alliance-ui-image';
import SearchClose from '../../../../icons/getty/clear.svg?icon';
import { AutoSuggestContext } from '../AutoSuggestContext';
import iStockStyles from './RecentAsset.istock.module.scss';
import GettyStyles from './RecentAsset.getty.module.scss';

const RecentAsset = ({ asset }) => {
  const autoSuggest = useContext(AutoSuggestContext);

  const siteMap = useSiteMap();
  const site = useSite();
  const styles = site.isGetty() ? GettyStyles : iStockStyles;

  const handleClickAsset = () => {
    autoSuggest.sendTracking('ASSET_CLICK');
  };

  const handleRemoveAsset = () => {
    autoSuggest.sendTracking('ASSET_REMOVE');
    autoSuggest.removeRecentlyViewedAsset(asset.id);
    autoSuggest.setRecentAssets(
      autoSuggest.recentAssets.filter((item) => item.id !== asset.id)
    );
  };

  const mosaicImageDimensions = () => {
    const maxImageWidth = asset.width ? asset.width : 0;
    const maxImageHeight = asset.height ? asset.height : 0;
    const desiredSize = 100;
    const ratio = Math.min(
      desiredSize / maxImageWidth,
      desiredSize / maxImageHeight
    );
    return {
      width: Math.floor(maxImageWidth * ratio),
      height: Math.floor(maxImageHeight * ratio),
    };
  };

  const imageDimensions = mosaicImageDimensions(asset);

  return (
    <div
      className={styles.wrapper}
      data-testid="asset-item"
      style={{
        '--width': imageDimensions.width,
        '--height': imageDimensions.height,
      }}
    >
      <a
        onClick={() => handleClickAsset()}
        href={siteMap.assetPath(asset.id)}
        target="_blank"
        data-testid="recent-asset-select"
        rel="noreferrer"
      >
        <Image
          className={styles.image}
          src={asset.deliveryUrl}
          alt="recently viewed asset"
        />
      </a>
      <div
        className={styles.clearIcon}
        onClick={() => handleRemoveAsset()}
        onKeyDown={() => handleRemoveAsset()}
        data-testid="recent-asset-remove"
        role="button"
        tabIndex={0}
      >
        <SearchClose />
      </div>
    </div>
  );
};

RecentAsset.propTypes = {
  asset: PropTypes.shape().isRequired,
};

export default RecentAsset;
