import React, { useEffect, useContext } from 'react';
import { useTranslations } from '@unisporkal/localization';
import { useSite } from '@unisporkal/sites';
import { useSearchBar } from '../../../../hooks';
import { AutoSuggestContext } from '../AutoSuggestContext';
import RecentSearch from '../RecentSearch/RecentSearch';
import iStockStyles from './RecentSearches.istock.module.scss';
import GettyStyles from './RecentSearches.getty.module.scss';

const RecentSearches = () => {
  const t = useTranslations();
  const searchBar = useSearchBar();
  const autoSuggest = useContext(AutoSuggestContext);

  const site = useSite();
  const styles = site.isGetty() ? GettyStyles : iStockStyles;
  const phrase = searchBar.searchParameters.phrase || '';

  useEffect(() => {
    const searches = autoSuggest.getRecentSearches();
    autoSuggest.setRecentSearches(searches);
  }, [searchBar.inFocus]);

  const handleClearAll = () => {
    autoSuggest.sendTracking('SEARCH_CLEAR_ALL');
    autoSuggest.clearRecentSearches();
    autoSuggest.setRecentSearches([]);
  };

  const isSelected = (index) => autoSuggest.keyIndex === index;

  const showRecentSearches = () =>
    searchBar.inFocus &&
    phrase.length === 0 &&
    autoSuggest.recentSearches.length > 0;

  if (showRecentSearches()) {
    return (
      <div className={styles.container}>
        <header
          className={styles.header}
          data-testid="searches-header"
        >
          <div className={styles.recentSearchesTitle}>
            {t('recent_searches_caps')}
          </div>
          <div className={styles.textDivider} />
          <button
            className={styles.clearAll}
            onClick={() => handleClearAll()}
            target="_self"
            data-testid="recent-searches-clear-all"
            type="button"
          >
            {t('clear_all')}
          </button>
        </header>
        <ul data-testid="searches-list">
          {autoSuggest.recentSearches.map((search, index) => (
            <RecentSearch
              key={search?.phrase}
              search={search}
              selected={isSelected(index)}
            />
          ))}
        </ul>
      </div>
    );
  }
  return null;
};

export default RecentSearches;
