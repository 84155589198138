import {
  SEARCH_BAR_UPDATE,
  SEARCH_BAR_UPDATE_SEARCH_PARAMETERS,
  SEARCH_BAR_UPDATE_OPTIONS,
  SEARCH_BAR_UPDATE_PEEKY_MODE,
  SEARCH_BAR_UPDATED,
  SEARCH_BAR_READY,
  SET_AUTOSUGGEST_CONTAINER_WIDTH,
} from '../actions/types';

// eslint-disable-next-line complexity
const calculateState = (state, action) => {
  switch (action.type) {
    case SEARCH_BAR_UPDATE:
      return {
        ...state,
        ...action.config,
      };
    case SEARCH_BAR_UPDATE_SEARCH_PARAMETERS:
      return {
        ...state,
        searchParameters: {
          ...state.searchParameters,
          ...action.parameters,
        },
      };
    case SEARCH_BAR_UPDATE_OPTIONS:
      return {
        ...state,
        options: {
          ...state.options,
          ...action.options,
        },
      };
    case SEARCH_BAR_UPDATE_PEEKY_MODE:
      return {
        ...state,
        peekyScrollMode: action.mode,
      };
    case SEARCH_BAR_UPDATED:
      return {
        ...state,
        status: 'updated',
      };
    case SEARCH_BAR_READY:
      return {
        ...state,
        status: 'ready',
      };
    case SET_AUTOSUGGEST_CONTAINER_WIDTH:
      return {
        ...state,
        config: {
          ...state.config,
          autoSuggestContainerWidth: action.width,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default (state, action) => {
  const newState = calculateState(state, action);
  return {
    ...newState,
    id: state.id + 1,
  };
};
